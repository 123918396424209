<template>
  <div class="widget violation-severity" :class="size">
    <h2 class="title">Violation Count by Severity</h2>
    <div class="severity-content">
      <div v-if="loading" class="site-loading">
        <LoadingSpinner /> Loading...
      </div>
      <div v-else-if="error" class="site-error">
        {{ error }}
      </div>
      <div v-else class="main-row">
        <div class="severity-total">
          <div class="big-number">
            {{ isDemoEnv ? '1,768' : totalViolations.toLocaleString() }}
          </div>
          <div class="total-label">Total Violations</div>
          <div class="delta negative" v-if="isDemoEnv">
            -18% (-1,458)
          </div>
          <div 
            class="delta" 
            :class="deltaClass" 
            v-else-if="previousTotal"
          >
            {{ deltaText }}
          </div>
        </div>
        <div class="severity-section">
          <div class="label">VIOLATIONS BY SEVERITY</div>
          <div class="bar-section">
            <HorizontalBarGraphic 
              ref="barGraph"
              v-if="isDemoEnv"
            />
            <HorizontalBarGraphic 
              ref="barGraph"
              v-else
              :barData="violationBarData"
              :total="totalViolations"
            />
          </div>
        </div>
      </div>
      <router-link
        :to="`/platform-governance/violations?org=${encodeURIComponent(
          this.$store.state.organization
        )}`"
        class="button"
      >VIEW VIOLATIONS</router-link>
    </div>
  </div>
</template>

<script>
import HorizontalBarGraphic from "@/components/Shared/HorizontalBarGraphic.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import AdtechService from "@/services/AdtechService";
import { getDashboardErrorMessage } from "@/ErrorMessaging";

// Demo data fixture
const DEMO_DATA = {
  totalViolations: 1768,
  previousTotal: 3226,
  deltaValue: -1458,
  violations: {
    low: 433,
    medium: 204,
    critical: 33
  }
};

export default {
  name: "ViolationCountBySeverity",
  components: {
    HorizontalBarGraphic,
    LoadingSpinner
  },
  props: {
    scans: {
      type: Array,
      required: true
    },
    size: {
      type: String,
      default: "full"
    }
  },
  data() {
    return {
      isDemoEnv: process.env.NODE_ENV === 'demo',
      violations: [],
      loading: true,
      error: null
    }
  },
  computed: {
    currentScan() {
      return this.scans && this.scans.length > 0 ? this.scans[0] : null;
    },
    previousScan() {
      return this.scans && this.scans.length > 1 ? this.scans[1] : null;
    },
    filterParams() {
      return this.$store.getters.currentFilterParams;
    },
    currentLowCount() {
    if (this.isDemoEnv) return DEMO_DATA.violations.low;
    const lowViolations = this.violations.filter(v => v.severity === 1 || v.severity === 0);
  return lowViolations.length;
  },
  
  currentMediumCount() {
    if (this.isDemoEnv) return DEMO_DATA.violations.medium;
    const mediumViolations = this.violations.filter(v => v.severity === 2 || v.severity === 3);
  return mediumViolations.length;


  },
  
  currentCriticalCount() {
    if (this.isDemoEnv) return DEMO_DATA.violations.critical;
    return this.violations.filter(v => v.severity === 5).length;
  },
    totalViolations() {
      return this.isDemoEnv 
        ? DEMO_DATA.totalViolations
        : this.violations.length;
    },
    previousTotal() {
      if (this.isDemoEnv) return DEMO_DATA.previousTotal;
      if (!this.previousScan) return null;
      return this.previousScan.violation_count || null;
    },
    deltaValue() {
      if (this.isDemoEnv) return DEMO_DATA.deltaValue;
      if (!this.previousTotal) return 0;
      return this.totalViolations - this.previousTotal;
    },
    deltaText() {
      // TODO: remove, or readd when previous scan totals are being properly calculated
      return '';
      /*const abs = Math.abs(this.deltaValue);
      const percentage = Math.round((abs / this.previousTotal) * 100);
      return `${this.deltaValue >= 0 ? '+' : '-'}${percentage}% (-${abs})`;*/
    },
    deltaClass() {
      return {
        'negative': this.deltaValue < 0,
        'positive': this.deltaValue >= 0
      };
    },
    violationBarData() {
      return [
        {
          value: this.currentLowCount,
          color: "yellow",
          name: "Low",
          onClick: () => this.navigateToViolations("Low")
        },
        {
          value: this.currentMediumCount,
          color: "orange",
          name: "Medium",
          onClick: () => this.navigateToViolations("Medium")
        },
        {
          value: this.currentCriticalCount,
          color: "red",
          name: "Critical",
          onClick: () => this.navigateToViolations("Critical")
        }
      ];
  }
  },
  methods: {
    navigateToViolations(severity) {
    this.$router.push({
      path: `/platform-governance/violations`,
      query: {
        org: encodeURIComponent(this.$store.state.organization),
        search: severity
      }
    })
  },
    async getViolations() {
      if (
        this.currentScan &&
        !this.isDemoEnv
      ) {
        try {
          const response = await AdtechService.call('reports.custom.scanRuleRuns', {
            scanid: this.currentScan.scanid,
            fields: [ 'violations' ],
            filter: this.filterParams || {}
          });
          const ruleRuns = response?.result?.['custom.scanRuleRuns'];
          if (!ruleRuns || ruleRuns.length === 0) return [];
          const violations = ruleRuns.reduce((acc, ruleRun) => {
            return acc.concat(ruleRun.violations || []);
          }, []);
          this.violations = violations;
        } catch (err) {
          this.triggerError({
            message: "ruleviolationsseverity - catch",
            error: err
          });
        } finally {
          this.loading = false;
        }
      }
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = getDashboardErrorMessage(this.$store.state.organization);
      this.loading = false;
    }
  },
  watch: {
    currentScan(newScan) {
      if (newScan) {
        this.getViolations();
      }
    },
    filterParams() {
      this.getViolations();
    },
  },
  mounted() {
    if (this.currentScan) {
      this.getViolations();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.violation-severity {
  .severity-content {
    width: 100%;
    padding: 0;
  }

  .main-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    gap: 2rem;

    @include media($medium, down) {
      flex-direction: column;
      align-items: center;
    }
  }

  .severity-total {
    text-align: left;
    min-width: 120px;
    flex-shrink: 0;

    @include media($medium, down) {
      text-align: center;
      margin-bottom: 1rem;
    }

    .big-number {
      font-size: 50px;
      font-weight: 600;
      color: $navy;
      line-height: 1;
      margin-bottom: 0.25rem;
    }

    .total-label {
      font-size: 18px;
      color: $navy;
      margin-bottom: 0.25rem;
    }

    .delta {
      font-size: 16px;
      
      &.negative {
        color: $green;
      }
      
      &.positive {
        color: $red;
      }
    }
  }

  .severity-section {
    flex: 1;
    width: 100%; 

    .label {
      font-size: 14px;
      color: $navy;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 0.25rem;
    }
  }

  .bar-section {
    width: 100%; 
    margin-bottom: 0.5rem;
  }

  .button {
    margin-top: 0.5rem;
    text-transform: uppercase;
    display: block;
    width: fit-content;
    margin-left: auto;
    font-size: 12px;
    padding: 0.4rem 0.8rem;
  }

  :deep(.HorizontalBarGraphic) {
  margin: 0;
  height: 24px;

  .item span {
    font-size: 11px;
    padding: 1px 6px;
    top: -14px;
  }
}

:deep(.pill-buttons) {
  margin: 0.9rem 0 0 0;
  display: flex;
  gap: 0.5rem;
  
  .bar-label-pill {
    margin: 0;
    font-size: 15px;
    padding: 4px 8px;
  }
}

  :deep(.pill-buttons) {
    margin: 0.9rem 0 0 0;
    display: flex;
    gap: 0.5rem;
    
    .bar-label-pill {
      margin: 0;
      font-size: 15px;
      padding: 4px 8px;
    }
  }
}
</style>