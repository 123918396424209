<template>
	<div class="view-container platform-governance-violations">
		<section class="view-header">
			<div class="view-title-with-btn">
				<div class="view-title">
					Platform Governance &amp; Standards - Violations
				</div>
				<PlatformGovernanceFilter
					:loading="loading"
					@filter-change="handleFilterChange"
					v-if="$store.state.scans && $store.state.scans.length > 0"
				/>
			</div>
			<div class="view-sublinks" v-if="!error && !loading">
				<LinkWithIcon
					icon="bar-plus-line-graph-data-up"
					text="Overview"
					link="/platform-governance/overview"
				/>
				<LinkWithIcon
					icon="list-ui"
					text="Details"
					:link="`/platform-governance/details?org=${encodeURIComponent(
						this.$store.state.organization
					)}`"
				/>
				<LinkWithIcon
					icon="warning"
					text="Violations"
					:link="`/platform-governance/violations?org=${encodeURIComponent(
						this.$store.state.organization
					)}`"
					v-bind:active="true"
				/>
				<!-- <LinkWithIcon
          icon="line-graph-data-up"
          text="Improve Score"
          :link="`/platform-governance/improve-score`"
        /> -->
			</div>
			<div>
				<LinkWithIcon
					icon="download"
					text="View Report Downloads"
					className="view-report-downloads-link"
					:link="`/platform-governance/download-reports?org=${encodeURIComponent(
						this.$store.state.organization
					)}`"
				/>
			</div>
		</section>
		<div class="workarea">
			<div class="widget full">
				<div v-if="error || loading">
					<div class="site-error" v-if="error || !loading">{{ error }}</div>
					<div class="site-loading" v-if="!error && loading">
						<LoadingSpinner /> Loading...
					</div>
				</div>

				<div class="table-wrapper" v-if="!error && !loading">
					<div class="table-header">
						<h2 class="title">Violations</h2>
						<div class="filters">
							<NInput v-model:value="searchQuery" placeholder="Search">
								<template #suffix>
									<DisplaySvg name="search" class="search-icon" />
								</template>
							</NInput>
						</div>
					</div>
					<div class="table-content">
						<div class="table-scroll-container">
							<NDataTable
								:columns="tableColumns"
								:data="paginatedData"
								:bordered="false"
								:pagination="false"
								:row-props="rowProps"
								:row-class-name="rowClassName"
								class="full platform-governance-violations-table"
							/>
						</div>
					</div>
					<div class="table-pagination">
						<NPagination
							v-model:page="page"
							:page-count="pageCount"
							:page-size="pageSize"
							:item-count="filteredViolations.length"
							@update:page="onPageChange"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import router from "@/router";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import { NDataTable, NInput, NPagination } from "naive-ui";
import ArrowDropdown from "@/components/Shared/ArrowDropdown.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";
import PlatformGovernanceFilter from "@/components/PlatformGovernance/PlatformGovernanceFilter.vue";
import { getDashboardErrorMessage } from "@/ErrorMessaging";
import store from "@/store/index.js";
import { h } from "vue";

export default {
	name: "PlatformGovernanceViolationsView",
	components: {
		DisplaySvg,
		NDataTable,
		NInput,
		NPagination,
		LoadingSpinner,
		LinkWithIcon,
		PlatformGovernanceFilter,
	},

	watch: {
		"$store.state.scans": function (newScans) {
			this.$nextTick(async () => {
				if (newScans && newScans.error) {
					this.loading = false;
					this.error = newScans.error;
				}

				if (newScans && newScans.length > 0) {
					await this.fetchViolations();
				}
			});
		},
		"$store.state.featurePermissions": function () {
			this.$nextTick(async () => {
				if (this.$store.state.featurePermissions) {
					if (!this.$store.state.featurePermissions.platformGovernance) {
						router.push("/");
					}
				}
			});
		},
		"$store.state.organization": function () {
			if (
				encodeURIComponent(this.$route.query.org) !==
				encodeURIComponent(this.$store.state.organization)
			) {
				router.push("/platform-governance/overview");
			}
		},
		"$route.query.search": {
			immediate: true,
			handler(newSearch) {
				if (newSearch) {
					this.searchQuery = newSearch;
				}
			},
		},
	},

	data() {
		const tableColumns = [
			{
				title: "Severity",
				key: "severity",
				minWidth: 100,
				render(row) {
					const severity = String(row.severity || "0");
					switch (severity) {
						case "0":
							return h("div", { class: "info" }, "Info");
						case "1":
							return h("div", { class: "low" }, "Low");
						case "2":
						case "3":
							return h("div", { class: "medium" }, "Medium");
						case "4":
							return h("div", { class: "severe" }, "Severe");
						case "5":
							return h("div", { class: "critical" }, "Critical");
						default:
							return h("div", { class: "info" }, "Info");
					}
				},
			},
			{
				title: "Site",
				key: "containerid",
				minWidth: 120,
				render(row) {
					return h(
						"div",
						{ class: "site-name" },
						store.getters.getContainerMeta(row.containerid, "name") ||
							row.container?.name || // Use optional chaining
							"Unknown Site"
					);
				},
			},
			{
				title: "Type",
				key: "violationcode",
				minWidth: 260,
				render(row) {
					const categories = row.rule?.categories || [];
					return h(
						"div",
						{},
						categories[0] || row.violationcode || "Unknown Type"
					);
				},
			},
			{
				title: "Rule Violated",
				key: "message",
				minWidth: 260,
				render(row) {
					return h("div", {}, row.message || "No message available");
				},
			},
			{
				title: "",
				key: "action",
				width: 80,
				render: (row) => {
					const orgId = store.state.organization;
					return h(ArrowDropdown, {
						options: [
							{
								key: row.rule?.ruleinstanceid || "unknown",
								link: `/platform-governance/violations/${
									row.rule.ruleinstanceid
								}?containerid=${row.containerid}&violationnum=${
									row.violationnum
								}&scanid=${row.scanid}&entityid=${
									row.entityid
								}&org=${encodeURIComponent(orgId)}`,
								text: "View Details",
							},
						],
					});
				},
			},
		];

		return {
			searchQuery: "",
			tableColumns,
			tableData: [],
			error: null,
			loading: true,
			page: 1,
			pageSize: 10,
		};
	},

	methods: {
		getSeverityText(severity) {
			const severityMap = {
				0: "info",
				1: "low",
				2: "medium",
				3: "medium",
				4: "severe",
				5: "critical",
			};
			return severityMap[String(severity)] || "info";
		},

		transformRunsToViolations(runs) {
			const transformedData = [];
			runs.forEach((run) => {
				if (run?.checks === 1 && run.violations?.length > 0) {
					run.violations.forEach((violation) => {
						transformedData.push({
							severity: violation.severity || run.rule?.severity, // Try violation severity first
							containerid: run.containerid,
							containername: run.container?.name,
							violationcode: violation.violationcode,
							message: violation.message,
							status: run.rule?.status,
							scanid: run.scanid,
							entityid: run.entityid,
							rule: {
								...run.rule,
								ruleinstanceid: run.ruleinstanceid,
							},
							entity: {
								...run.entity,
								entityid: run.entityid,
							},
							violationnum: violation.violationnum,
							categories: run.rule?.categories || [],
						});
					});
				}
			});

			return transformedData;
		},
		onPageChange(page) {
			this.page = page;
		},

		async handleFilterChange(filters) {
			this.loading = true;
			try {
				const { rules } = await store.dispatch("applyFilters", filters);

				if (rules.error) {
					this.triggerError({
						message: "Error fetching filtered rule runs",
						error: rules.error,
					});
					return;
				}

				const runs = rules?.result?.["custom.scanRuleRuns"] || [];
				this.tableData = this.transformRunsToViolations(runs);
			} catch (error) {
				this.triggerError({
					message: "Error applying filters",
					error,
				});
			} finally {
				this.loading = false;
			}
		},

		async fetchViolations() {
			this.loading = true;
			try {
				const response = await store.dispatch("fetchFilteredRuleRuns", {
					fields: ["container", "rule", "entity", "violations"],
				});

				if (response.error) {
					this.triggerError({
						message: "Error fetching rule runs",
						error: response.error,
					});
					return;
				}

				const runs = response?.result?.["custom.scanRuleRuns"] || [];
				this.tableData = this.transformRunsToViolations(runs);
			} catch (error) {
				this.triggerError({
					message: "Error fetching violations",
					error,
				});
			} finally {
				this.loading = false;
			}
		},

		triggerError(obj) {
			console.error(obj);
			this.error = getDashboardErrorMessage(this.$store.state.organization);
			this.loading = false;
		},

		rowProps(row) {
			return {
				style: "cursor: pointer;",
				onClick: () => {
					const orgId = this.$store.state.organization;
					const ruleInstanceId = row.rule.ruleinstanceid;
					const containerId = row.containerid;
					const violationNum = row.violationnum;
					const scanId = row.scanid;
					const entityId = row.entityid;

					router.push(
						`/platform-governance/violations/${ruleInstanceId}?containerid=${containerId}&violationnum=${violationNum}&scanid=${scanId}&entityid=${entityId}&org=${encodeURIComponent(
							orgId
						)}`
					);
				},
			};
		},
	},

	computed: {
		filteredViolations() {
			if (!this.tableData || !Array.isArray(this.tableData)) return [];

			if (!this.searchQuery) {
				return this.tableData;
			}

			const searchTerm = this.searchQuery.toLowerCase().trim();

			// If exact match with severity terms, filter by severity only
			const exactSeverityTerms = ["low", "medium", "critical"];
			if (exactSeverityTerms.includes(searchTerm)) {
				return this.tableData.filter(
					(violation) =>
						this.getSeverityText(violation.severity).toLowerCase() ===
						searchTerm
				);
			}

			// Check if search term starts with a partial severity word
			const severityStartsWith = exactSeverityTerms.some((term) =>
				term.startsWith(searchTerm)
			);
			if (severityStartsWith) {
				// Only search in severity field
				return this.tableData.filter((violation) =>
					this.getSeverityText(violation.severity)
						.toLowerCase()
						.startsWith(searchTerm)
				);
			}

			// Otherwise search across all fields
			return this.tableData.filter(
				(violation) =>
					// Site name
					(violation.containername || "").toLowerCase().includes(searchTerm) ||
					(violation.containerid || "").toLowerCase().includes(searchTerm) ||
					// Type/Category
					(violation.rule?.categories || []).some((cat) =>
						cat.toLowerCase().includes(searchTerm)
					) ||
					// Rule message
					(violation.message || "").toLowerCase().includes(searchTerm) ||
					// Violation code
					(violation.violationcode || "").toLowerCase().includes(searchTerm)
			);
		},

		paginatedData() {
			const start = (this.page - 1) * this.pageSize;
			const end = start + this.pageSize;
			return this.filteredViolations
				? this.filteredViolations.slice(start, end)
				: [];
		},

		pageCount() {
			return Math.ceil(this.filteredViolations.length / this.pageSize);
		},
	},
	async mounted() {
		if (this.$route.query.search) {
			this.searchQuery = this.$route.query.search;
		}
		if (this.$store.state.scans?.error) {
			this.loading = false;
			this.error = this.$store.state.scans.error;
		} else if (this.$store.state.scans) {
			await this.fetchViolations();
		}

		if (this.$store.state.featurePermissions?.platformGovernance === false) {
			router.push("/");
		}
	},
};
</script>

<style lang="scss">
.platform-governance-violations {
	.view-title-with-btn {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1.5rem;

		@media (max-width: 768px) {
			flex-direction: column;
			align-items: flex-start;
			gap: 1rem;
		}
	}

	.widget {
		display: block;
	}

	.table-wrapper {
		height: 850px;
		display: flex;
		flex-direction: column;
	}

	.table-header {
		padding: 16px;
		background-color: #fff;
		border-bottom: 1px solid #e0e0e0;
	}

	.table-pagination {
		padding: 16px;
		background-color: #fff;
		border-top: 1px solid #e0e0e0;
	}

	.table-content {
		flex-grow: 1;
		overflow: hidden;
		position: relative;
	}

	.table-scroll-container {
		height: 100%;
		overflow-y: auto;
		position: relative;

		&::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba(100, 100, 100, 0.5);
			border-radius: 5px;
		}

		&::-webkit-scrollbar-track {
			background-color: transparent;
		}

		scrollbar-width: thin;
		scrollbar-color: rgba(100, 100, 100, 0.5) transparent;
	}

	.n-data-table {
		height: 100%;
	}

	.n-data-table-base-table {
		min-height: 100%;
	}

	.search-icon {
		height: 16px;
		width: 16px;
		position: relative;
		top: -5px;
		color: $dark-gray;
	}

	.filter-icon {
		height: 16px;
		width: 16px;
		margin-right: 10px;
		position: relative;
		top: 2px;
	}

	.filter-button {
		margin-right: 20px;
	}

	.fixed-height-row {
		height: 72px !important;
	}

	.fixed-height-row td {
		padding: 10px !important;
		vertical-align: middle !important;
	}

	.n-data-table-td {
		padding: 16px 12px !important;
		word-break: break-word;
		vertical-align: middle !important;
	}

	.platform-governance-violations-table {
		.n-data-table-td {
			vertical-align: middle;
		}
	}
}
</style>
