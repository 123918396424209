<template>
  <router-link
    :to="link"
    v-bind:class="getClasses(color, true, className)"
    v-if="link"
  >
    {{ text }} <DisplaySvg name="caret-right" class="caret" />
  </router-link>
  <div
    v-bind:class="getClasses(color, true, className)"
    v-else
    @click="handleClick"
  >
    {{ text }}
  </div>
</template>

<script>
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
export default {
  name: "Pill",
  components: { DisplaySvg },
  props: {
    color: {
      type: String,
      validator(value) {
        return [
          "red",
          "orange",
          "yellow",
          "green",
          "blue",
          "navy",
          "light-blue",
          "teal",
          "light-teal",
        ].includes(value);
      },
      default: "navy",
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    link: {
      type: String,
    },
    className: {
      type: String,
    },
  },
  methods: {
    getClasses(color, hasInteraction, className) {
      let classes = {
        pill: true,
        hover: hasInteraction, // Always add hover if clickable or has link
      };
      if (color) {
        classes[color] = true;
      }
      if (className) {
        classes[className] = true;
      }
      return classes;
    },
    handleClick() {
      this.$emit('pill-click');
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.pill {
  white-space: nowrap;
  padding: 6px 16px;
  border-radius: 30px;
  font-weight: 700;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 16px;
  display: inline-block;

  .caret {
    width: 8px;
    display: inline-block;
    position: relative;
    top: 2px;
    margin-left: 5px;
  }

  &.hover {
    cursor: pointer;
    @include hover {
      &.red {
        background-color: $red;
        color: $white;
      }
      &.orange {
        background-color: $orange;
        color: $white;
      }
      &.yellow {
        background-color: $yellow;
        color: $white;
      }
      &.green {
        background-color: $green;
        color: $white;
      }
      &.blue {
        background-color: $blue;
        color: $white;
      }
      &.navy {
        background-color: $navy;
        color: $white;
      }
      &.light-blue {
        background-color: $light-blue;
        color: $white;
      }
      &.teal {
        background-color: $teal;
        color: $white;
      }
      &.dark-teal {
        background-color: $dark-teal;
        color: $white;
      }
    }
  }

  &.red {
    border: 1px solid $red;
    background-color: $red-lighter;
    color: $red-darker;
  }
  &.orange {
    border: 1px solid $orange;
    background-color: $orange-lighter;
    color: $orange-darker;
  }
  &.yellow {
    border: 1px solid $yellow;
    background-color: $yellow-lighter;
    color: $yellow-darker;
  }
  &.green {
    border: 1px solid $green;
    background-color: $green-lighter;
    color: $green-darker;
  }
  &.blue {
    border: 1px solid $blue;
    background-color: $blue-lighter;
    color: $blue-darker;
  }
  &.navy {
    border: 1px solid $navy;
    background-color: $navy-lighter;
    color: $navy-darker;
  }
  &.light-blue {
    border: 1px solid $light-blue;
    background-color: $light-blue-lighter;
    color: $light-blue-darker;
  }
  &.teal {
    border: 1px solid $teal;
    background-color: $teal-lighter;
    color: $teal-darker;
  }
  &.dark-teal {
    border: 1px solid $dark-teal;
    background-color: $dark-teal-lighter;
    color: $dark-teal-darker;
  }
}
</style>
